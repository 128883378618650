import React from 'react';
import Image from 'next/image';
import { storyblokEditable } from '@storyblok/react';
/*
@tailwind unpurge

gap-4 gap-8 gap-12
*/
export default function Gallery({ blok }) {
  return (
    <div className={`grid grid-cols-${blok.items.length} ${blok.gap ? 'gap-'+blok.gap : 'gap-4'} ${blok.verticalAlignment || '' }`} {...storyblokEditable(blok)}>
      {blok?.items && blok?.items.map((item, i) => <Image key={blok._uid + i} src={item.filename} alt={item.alt || `Gallery Image ${i+1}`} layout="responsive" width={182} height={60} />)}
    </div>
  );
}
